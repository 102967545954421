@import url(https://fonts.googleapis.com/css2?family=Lato:wght@100;400&family=Montserrat:wght@100&family=Poppins:wght@300&display=swap);
body {
  font-family: "Lato" !important;
  font-size: 16px;
  height: 100vh;
  background: url(/static/media/background.a3606c5c.png);
  background-size: cover;

}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scrollbar-color: #f6f6f6 #d5d5d5;
  scrollbar-color: var(--scrollbar-track) var(--scrollbar-thumb);
  scrollbar-width: thin;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.gfsJVD {
  display: inline !important;
}
:root {
  --scrollbar-thumb: #d5d5d5;
  --scrollbar-track: #f6f6f6;
}

*::-webkit-scrollbar {
  width: 0.6875rem;
}
*::-webkit-scrollbar-track {
  border-radius: 0.9375rem;
  background: #f6f6f6;
  background: var(--scrollbar-track);
}

*::-webkit-scrollbar-thumb {
  border-radius: 0.9375rem;
  background: #d5d5d5;
  background: var(--scrollbar-thumb);
}

.loading {
  z-index: 10000;
  border-radius: 50%;
  width: 1.5rem;
  height: 1.5rem;
  border: 0.25rem solid rgb(189, 181, 181);
  border-top-color: #552c62;
  -webkit-animation: spin 0.2s infinite linear;
          animation: spin 0.2s infinite linear;
  position: absolute;
  top: 20px;
  right: 10px;
}
.lines {
  position: absolute;
  top: 29%;
  left: 4%;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@media (max-width: 1024px) {
  .loading {
    top: 10px;
    right: 10px;
  }
  *::-webkit-scrollbar {
    width: 0.3rem;
  }
}

@-webkit-keyframes slide-out {
  from {
    margin-left: 0px;
  }
  to {
    margin-left: -22rem;
  }
}

@keyframes slide-out {
  from {
    margin-left: 0px;
  }
  to {
    margin-left: -22rem;
  }
}

@-webkit-keyframes grow-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes grow-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

